import React from "react";
import { AppModal } from "./AppModal";
import { IModalOptions } from "./ModalOptions";

type Props = {
   promptToInstall: () => void;
};

export const InstallModal = ({ promptToInstall }: Props) => {
   const options: IModalOptions = {
      btnId: "open_install_modal",
      modalId: "installModal",
      modalTitleId: "installModalLabel",
      modalTitle: "Қош келдіңіз!",
      modalBody: <p>Біздің қосымшаны құрылғыңызға орнатыңыз!</p>,
      btnCloseClick: () => {},
      btnFooter: (
         <button
            id="close_install_modal"
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={() => {
               console.log("install");
               promptToInstall();
            }}
         >
            Орнату
         </button>
      ),
   };

   return <AppModal {...options} />;
};
