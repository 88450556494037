export const enterFullScreen = () => {
   if (!document.fullscreenElement) {
      const docEl = document.documentElement as HTMLElement & {
         webkitRequestFullscreen?: () => Promise<void>;
      };
      if (docEl.requestFullscreen) {
         docEl.requestFullscreen().catch((err) => {
            console.error("Full screen request failed:", err);
         });
      } else if (docEl.webkitRequestFullscreen) {
         // iOS үшін қосымша қолдау
         docEl.webkitRequestFullscreen();
      }
      window.screen.width > 1200 && document.getElementById("ttr-material-button")?.click();
   }
};
