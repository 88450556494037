// This app uses Themify Icons (https://themify.me/themify-icons)

export const sidebarLinks = [
   {
      href: "/Account",
      icon: "ti-user",
      // icon: "ti-home",
      text: "Жеке Кабинет",
   },
   // {
   //    href: "/Profil",
   //    icon: "ti-book",
   //    text: "Сабақтар",
   // },
   // {
   //    href: "/Test5",
   //    icon: "ti-check-box",
   //    text: "ҰБТ тапсыру",
   // },
   {
      href: "/Test1",
      icon: "ti-pencil-alt",
      text: "Пәндік тест тапсыру",
   },
   // {
   //    href: "/TestAnaliz",
   //    icon: "ti-write",
   //    text: "Тест - Анализ",
   // },
   {
      href: "/NuskaList",
      icon: "ti-check-box",
      text: "Тест - Нұсқа",
      isNuska: true,
   },
];
