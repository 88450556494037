import { useAppDispatch } from "../../redux/redux";
import { AppModal } from "./AppModal";
import { IModalOptions } from "./ModalOptions";
import { setLeaveTest } from "../../redux/slices/test/test.slice";

export const FinishTestLeaveModal = () => {
   const dispatch = useAppDispatch();

   const options: IModalOptions = {
      btnId: "open_leave_modal",
      modalId: "finishTestLeaveModal",
      modalTitleId: "finishTestLeaveModalLabel",
      modalTitle: "Ескерту!",
      modalBody: (
         <>
            <p>
               Сіз тест тапсыру бетінен кетіп <strong>ережені бұздыңыз!</strong>
            </p>
            <p>Тест аяқталды!</p>
         </>
      ),
      btnCloseClick: () => {},
      btnFooter: (
         <button id="finishTestLeaveBtnClick" type="button" className="btn btn-secondary" data-dismiss="modal">
            Ok, екінші қайталанбайды
         </button>
      ),
   };

   return <AppModal {...options} />;
};
